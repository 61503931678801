<template>
  <div>
    <div class="container-fluid mt-4">
      <div class="row justify-content-center">
        <div class="col-10">
          <div class="px-5" v-if="!this.userProfile.trialEndsAt">
            <div>
              <p>
                You can extend your trial by clicking the following button.
              </p>
              <base-button :loading="isExtendingTrial" class="btn primary text mt-4" type="primary" @click="extendTrial">
                I want to extend my trial
              </base-button>
            </div>
          </div>
          <div class="px-5" v-else>
            <p>
              Your trial period has already been extended!<br>
              Please <a @click="openCrisp" href="javascript:;">contact us</a> if you need another extension.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import SwalModalMixin from '@/views/Mixins/SwalModalMixin.vue';
  import { mapGetters } from 'vuex';
  import controller from '@/controller';

  export default {
    computed: {
      ...mapGetters({ currentUser: 'getCurrentUser', userProfile: 'getUserProfile' }),
    },
    data() {
      return {
        isExtendingTrial: false
      }
    },
    methods: {
      openCrisp() {
        $crisp.push(['do', 'chat:open']);
      },
      extendTrial() {
        this.isExtendingTrial = true;
        controller.extendTrial(this.currentUser, this.userProfile.uid, this.$route.params.id)
          .then(() => {
            this.swalModal({
              title: 'Good news',
              text: 'Your trial period has been extended!',
              type: 'success',
            });
            this.isExtendingTrial = false;
          })
          .catch(error => {
            if (error.response.status === 403) {
              const message = 'Your trial period has already been extended! Please contact us if you need another extension';
              this.swalModal({
                title: 'Sorry',
                text: message,
                showCancelButton: false,
                type: 'warning',
              });
            } else if (error.response.status === 404) {
              this.swalModal({
                title: 'Sorry',
                text: `Code ${this.$route.params.id} not found.`,
                showCancelButton: false,
                type: 'error',
              });
            }
            this.isExtendingTrial = false;
          });
      },
    },
    mixins: [SwalModalMixin]
  }
</script>

